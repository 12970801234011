<template>
  <div class="radio-button-list-item" @click="click">
    <div class="circle-wrapper">
      <base-radio-button-list-item-circle
        :checked="reservationPurpose.checked"
      />
    </div>
    <div class="name-time-wrapper">
      <span class="reservation-purpose-name" data-test="name">{{
        reservationPurposeName
      }}</span
      ><span class="reservation-purpose-time" data-test="time">{{
        $t('parts.reservationPurposeTime', { time: reservationPurpose.time })
      }}</span>
    </div>
  </div>
</template>

<script>
import BaseRadioButtonListItemCircle from '@/components/parts/atoms/BaseRadioButtonListItemCircle'

export default {
  name: 'RadioButtonListItem',

  components: {
    BaseRadioButtonListItemCircle
  },

  props: {
    reservationPurpose: {
      id: { type: Number },
      checked: { type: Boolean },
      name: { type: String },
      nameEnglish: { type: String },
      time: { type: Number }
    }
  },

  computed: {
    reservationPurposeName() {
      return this.$i18n.locale === 'en'
        ? this.reservationPurpose.nameEnglish || this.reservationPurpose.name
        : this.reservationPurpose.name
    }
  },

  methods: {
    click() {
      this.$emit('click', this.reservationPurpose.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-button-list-item {
  @include clearfix;
  float: left;
  width: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #{$plus_black};
  margin: 6px 0 6px 0;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  > .circle-wrapper {
    float: left;
    width: 44px;
  }
  > .name-time-wrapper {
    float: right;
    width: 100%;
    margin-right: -44px;
    padding-right: 44px;
    box-sizing: border-box;
    margin-top: 8px;
    > .reservation-purpose-name {
      float: left;
      word-wrap: normal;
      font-size: 18px;
      width: 100%;
      margin-right: -70px;
      padding-right: 70px;
      box-sizing: border-box;
      overflow-wrap: break-word;
    }
    > .reservation-purpose-time {
      float: right;
      font-size: 18px;
      width: 70px;
    }
  }
}
</style>
