<template>
  <div class="reservation-purpose">
    <base-loading :waitFlg="waitFlg" />
    <div class="page-title">
      <base-page-title>{{
        $t('reservationPurpose.pageTitle')
      }}</base-page-title>
    </div>
    <div class="reservation-progress-bar">
      <progress-bar v-bind="progressBarData" />
    </div>
    <div class="reservation-radio-button-list">
      <radio-button-list
        :displayReservationPurposes="displayReservationPurposes"
        :scenario="scenario"
      />
    </div>
    <div class="decision-button">
      <base-decision-button
        :disabled="!isChecked"
        @click="pushToReservationDateSelect"
        >{{ $t('common.buttonNext') }}</base-decision-button
      >
    </div>
    <div class="cancel-button">
      <base-cancel-button @click="backPage">{{
        $t('common.buttonReturn')
      }}</base-cancel-button>
    </div>
    <announce-popup
      v-if="alertFlg"
      :type="'failure'"
      :title="$t('reservationPurpose.popupTitle')"
      :buttons="buttons.map(v => $t(v))"
      @close="alertFlg = false"
      >{{ $t(popupMessage) }}</announce-popup
    >
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton.vue'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton.vue'
import BaseLoading from '@/components/parts/atoms/BaseLoading.vue'
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import RadioButtonList from '@/components/parts/organisms/RadioButtonList'
import { mapGetters } from 'vuex'
import ReloadConfirm from '@/components/mixins/ReloadConfirm'
import { getAxiosObject } from '@/utils/library'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { createProgressBarDataForReservation } from '@/utils/createProgressBarData'

export default {
  name: 'ReservationPurpose',

  components: {
    BasePageTitle,
    BaseDecisionButton,
    BaseCancelButton,
    ProgressBar,
    RadioButtonList,
    AnnouncePopup,
    BaseLoading
  },

  mixins: [ReloadConfirm],

  data() {
    return {
      displayReservationPurposes: [],
      alertFlg: false,
      popupMessage: '',
      buttons: ['reservationPurpose.popupButtonClose'],
      progressBarData: {},
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      scenario: 'auth/scenario',
      changeReservation: 'reservation/getChangeReservation',
      newReservation: 'reservation/getNewReservation',
      owner: 'owner/getOwner'
    }),
    isChecked() {
      return this.displayReservationPurposes.some(v => v.checked)
    }
  },

  created() {
    this.progressBarData = createProgressBarDataForReservation(this.scenario, 1)
    this.fetchReservationPurposes()
  },

  methods: {
    async fetchReservationPurposes() {
      try {
        this.waitFlg = true
        const axiosObject = getAxiosObject()
        const res = await axiosObject.get('/reservation-purposes')
        this.$store.dispatch(
          'reservationPurpose/addReservationPurposes',
          res.data.reservationPurposes
        )
        const filteredReservationPurposes = this.filterReservationPurposes(
          res.data.reservationPurposes
        ).sort((a, b) => a.order - b.order)
        this.makeDisplayReservationPurposes(filteredReservationPurposes)
        this.waitFlg = false
      } catch (ex) {
        this.alertFlg = true
        this.waitFlg = false
        if (ex.response?.status === 401) {
          this.buttons = ['reservationPurpose.popupButtonToLogin']
        } else {
          this.popupMessage = 'reservationPurpose.popupMessageError'
          this.buttons = ['reservationPurpose.popupButtonClose']
        }
      }
    },
    filterReservationPurposes(resReservationPurposes) {
      return this.scenario === 'first_reservation' ||
        (this.owner && this.owner.showId === '')
        ? resReservationPurposes.filter(v => v.firstShowFlg === 1)
        : resReservationPurposes.filter(v => v.secondShowFlg === 1)
    },
    makeDisplayReservationPurposes(filteredReservationPurposes) {
      let reservationPurposeId =
        this.scenario === 'change_reservation'
          ? this.changeReservation.reservationPurposeId
          : this.newReservation.reservationPurposeId
      if (reservationPurposeId === 0) {
        reservationPurposeId = filteredReservationPurposes[0].id
        this.scenario === 'change_reservation'
          ? this.$store.dispatch(
              'reservation/setChangeReservationPurpose',
              filteredReservationPurposes[0]
            )
          : this.$store.dispatch(
              'reservation/setNewReservationPurposeId',
              reservationPurposeId
            )
      }
      this.displayReservationPurposes = filteredReservationPurposes.map(
        (v, i) => {
          return {
            id: v.id,
            name: v.name,
            nameEnglish: v.nameEnglish,
            time: v.time,
            checked:
              reservationPurposeId === 0 && i === 0
                ? true
                : reservationPurposeId === v.id
                ? true
                : false
          }
        }
      )
    },
    pushToReservationDateSelect() {
      this.$router.push({ name: 'ReservationDateSelect' })
    },
    backPage() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-purpose {
  margin-bottom: 100px;
  > .page-title {
    margin-top: 70px;
  }
  > .reservation-progress-bar {
    margin-top: 28px;
  }
  > .reservation-radio-button-list {
    margin-top: 99px;
    @include clearfix;
  }
  > .decision-button {
    margin-top: 86px;
  }
  > .cancel-button {
    margin-top: 23px;
  }
}
@media (max-width: $global-container) {
  .reservation-purpose {
    > .page-title {
      margin-top: 63px;
    }
    > .reservation-progress-bar {
      margin-top: 24px;
    }
    > .reservation-radio-button-list {
      margin-top: 65px;
    }
    > .decision-button {
      margin-top: 73px;
    }
  }
}
</style>
