<template>
  <div
    class="base-radio-button-list-item-circle"
    :checked="checked"
    @click="click"
  >
    <div class="outer-circle" v-bind:class="{ gray: !this.checked }">
      <div class="inner-circle" v-if="checked"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseRadioButtonListItemCircle',

  props: {
    checked: { type: Boolean }
  },

  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-radio-button-list-item-circle {
  width: 44px;
  height: 44px;
  position: relative;
  float: left;
  > .outer-circle {
    width: 24px;
    height: 24px;
    border-radius: 15px;
    border: solid 3px #{$plus_orange};
    position: absolute;
    top: 7px;
    left: 7px;
    .inner-circle {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 30px;
      background-color: #{$plus_orange};
      vertical-align: middle;
      position: absolute;
      top: 3px;
      left: 3px;
    }
  }
  .gray {
    border: solid 3px #{$plus_bc_gray};
  }
}
</style>
