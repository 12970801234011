<template>
  <div class="radio-button-list">
    <div class="radio-button-list-class">
      <radio-button-list-item
        v-for="reservationPurpose in displayReservationPurposes"
        :key="reservationPurpose.id"
        :reservationPurpose="reservationPurpose"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
import RadioButtonListItem from '@/components/parts/molecules/RadioButtonListItem'

export default {
  name: 'RadioButtonList',

  components: {
    RadioButtonListItem
  },

  props: {
    displayReservationPurposes: { type: Array },
    scenario: { type: String }
  },

  methods: {
    handleClick(reservationPurposeId) {
      this.displayReservationPurposes.forEach(v => {
        reservationPurposeId === v.id ? (v.checked = true) : (v.checked = false)
      })
      const reservationPurpose = this.displayReservationPurposes.find(
        v => v.id === reservationPurposeId
      )
      const selectReservationPurpose = {
        id: reservationPurposeId,
        name: reservationPurpose.name,
        nameEnglish: reservationPurpose.nameEnglish
      }
      this.scenario === 'change_reservation'
        ? this.$store.dispatch(
            'reservation/setChangeReservationPurpose',
            selectReservationPurpose
          )
        : this.$store.dispatch(
            'reservation/setNewReservationPurposeId',
            reservationPurposeId
          )
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-button-list {
  width: 100%;
  min-width: 300px;
  height: 100%;
  background: red;
  > .radio-button-list-class {
    min-width: 300px;
    height: 100%;
    margin: 0 auto;
  }
  @include selector-width('.radio-button-list-class', 335px);
}
</style>
